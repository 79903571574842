var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block highway-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-steps",
          { attrs: { active: _vm.activeStep, "align-center": "" } },
          [
            _c("el-step", {
              attrs: { title: "Step 1", description: "General Details" },
              nativeOn: {
                click: function($event) {
                  return _vm.goToStep(0)
                }
              }
            }),
            _c("el-step", {
              attrs: { title: "Step 2", description: "Images" },
              nativeOn: {
                click: function($event) {
                  return _vm.goToStep(1)
                }
              }
            })
          ],
          1
        ),
        _c("el-divider"),
        _vm.activeStep === 0
          ? _c(
              "div",
              [
                _c(
                  "el-form",
                  {
                    ref: "formData",
                    staticClass: "demo-form",
                    attrs: {
                      model: _vm.formData,
                      "status-icon": "",
                      rules: _vm.rules,
                      "label-width": "160px",
                      "label-position": "left"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "Enabled", prop: "enabled" } },
                      [
                        _c("el-switch", {
                          model: {
                            value: _vm.formData.enabled,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "enabled", $$v)
                            },
                            expression: "formData.enabled"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Title", prop: "title" } },
                      [
                        _c("el-input", {
                          attrs: {
                            name: "title",
                            required: "",
                            placeholder: "Title"
                          },
                          model: {
                            value: _vm.formData.title,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "title", $$v)
                            },
                            expression: "formData.title"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Redeemable Points",
                          prop: "redeemablePoints"
                        }
                      },
                      [
                        _c("el-input-number", {
                          attrs: {
                            name: "redeemablePoints",
                            precision: 2,
                            step: 1,
                            min: 0
                          },
                          model: {
                            value: _vm.formData.redeemablePoints,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "redeemablePoints", $$v)
                            },
                            expression: "formData.redeemablePoints"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Description", prop: "description" } },
                      [
                        _c("editor", {
                          attrs: {
                            "api-key":
                              "9y5omcd9ljputatls1a4ziy5utsive3zotsnp4mfpy59prba",
                            init: {
                              plugins: [
                                "lists link image paste help wordcount"
                              ],
                              toolbar:
                                "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help"
                            }
                          },
                          model: {
                            value: _vm.formData.description,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "description", $$v)
                            },
                            expression: "formData.description"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Validity", prop: "validity" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            "start-placeholder": "Start date",
                            "end-placeholder": "End date",
                            "default-time": ["00:00:00", "23:59:59"],
                            "picker-options": _vm.validPickerOptions
                          },
                          model: {
                            value: _vm.validity,
                            callback: function($$v) {
                              _vm.validity = $$v
                            },
                            expression: "validity"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Priority", prop: "priority" } },
                      [
                        _c("el-input-number", {
                          attrs: {
                            name: "priority",
                            required: "",
                            placeholder: "Priority"
                          },
                          model: {
                            value: _vm.formData.priority,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "priority", $$v)
                            },
                            expression: "formData.priority"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading"
                              },
                              { name: "waves", rawName: "v-waves" }
                            ],
                            attrs: { type: "success" },
                            on: { click: _vm.submitForm }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("global.form.save")) + " "
                            )
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [{ name: "waves", rawName: "v-waves" }],
                            attrs: { type: "reset" },
                            on: { click: _vm.resetForm }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("global.form.reset")) + " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.activeStep === 1 && _vm.isEdit
          ? _c(
              "div",
              [
                _c(
                  "el-form",
                  {
                    ref: "formData",
                    staticClass: "demo-form",
                    attrs: {
                      model: _vm.formData,
                      "status-icon": "",
                      rules: _vm.rules,
                      "label-width": "160px",
                      "label-position": "left"
                    }
                  },
                  [
                    _c("file-upload", {
                      attrs: { drag: true, "file-list": [] },
                      on: { change: _vm.fileUploaded }
                    }),
                    _c("el-divider"),
                    _c(
                      "el-table",
                      {
                        ref: "dragTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.formData.campaignsImage,
                          "row-key": "id",
                          border: "",
                          fit: "",
                          "highlight-current-row": ""
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            width: "80",
                            align: "center",
                            label: "Drag"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("i", {
                                      staticClass: "el-icon-rank drag-icon",
                                      attrs: { "data-index": scope.$index }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            570729951
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "80",
                            align: "center",
                            label: "Priority",
                            prop: "priority"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "Cover",
                            width: "180"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-switch", {
                                      attrs: {
                                        disabled: scope.row.isCover
                                          ? true
                                          : false,
                                        value: scope.row.isCover,
                                        "validate-event": false
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.handleCoverChange(
                                            scope.$index
                                          )
                                        }
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3756260388
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "Image",
                            width: "180"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "100px",
                                        height: "100px"
                                      },
                                      attrs: {
                                        src: scope.row.image,
                                        "preview-src-list": [scope.row.image],
                                        fit: "scale-down",
                                        lazy: ""
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3993293834
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: _vm.$t("table.actions"),
                            width: "100"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    !scope.row.isCover
                                      ? _c("el-button", {
                                          attrs: {
                                            size: "small",
                                            type: "danger",
                                            icon: "el-icon-delete"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleDelete(
                                                scope.row,
                                                scope.$index
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            306250871
                          )
                        })
                      ],
                      1
                    ),
                    _c("br")
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("br"),
                    _c(
                      "el-button",
                      {
                        directives: [{ name: "waves", rawName: "v-waves" }],
                        attrs: { type: "success" },
                        on: { click: _vm.saveImages }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "text-small text-italic",
                        attrs: { color: "grey" }
                      },
                      [_vm._v(" (save after modifications) ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }